
import {
  defineComponent,
  getCurrentInstance,
  watch,
  toRefs,
  reactive,
  ref,
  PropType
} from 'vue'
import { useStore } from 'vuex'
import {
  IItemStatus,
  IVerbal,
  IVerbalItem
} from '@/utils/interfaces/iVerbal'
import { chain } from 'lodash'
import apiUseCase from '@/usecase/apiUseCase'
import HjpDataList from '@/views/components/list/HjpDataList.vue'
import { StatusVerbal } from '@/utils/enum/StatusVerbal'
import ListVerbalReport from './components/ListVerbalReport.vue'
import FineForm from './form/FineForm.vue'

const endpoint = '/transaction/v1/Verbal'

export default defineComponent({
  name: 'ActiveReportVerbal',
  components: {
    HjpDataList,
    ListVerbalReport,
    FineForm
  },
  props: {
    searchValue: {
      type: String,
      default: ''
    },
    tabIndex: {
      type: Number,
      default: 0
    },
    filterStatus: {
      type: Array as PropType<number[]>,
      default: () => []
    }
  },
  setup(props: any) {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const {
      searchValue,
      tabIndex,
      filterStatus
    } = toRefs(props)
    const store = useStore()
    const params = reactive({
      showHeader: false,
      endpoint,
      totalRow: 5,
      searchValue: searchValue.value,
      fieldSearch: ['query'],
      filters: [['verbalStatusList', '=', filterStatus.value]]
    })
    const refHjpDataList = ref()
    const itemSelected = ref()
    const mapResponse = (items: IVerbal[]): IVerbalItem[] => {
      const manageData = chain(items).groupBy((group) => group.Controller.Rit.TransactionPp.RosterCode)
        .map((val: IVerbal[]) => {
          const CodeSchedule = val[0].Controller.Rit.TransactionPp.RosterCode
          const RoutesCityName = val[0].Controller.Rit.RouteName
          const Verbals = val
          return {
            CodeSchedule,
            RoutesCityName,
            Verbals
          }
        }).value()
      // console.log('data management', manageData)
      return manageData
    }
    const processChangeStatus = (itemStatus: IItemStatus, itemChoice: IVerbal) => {
      itemSelected.value = itemChoice
      if (itemStatus.label === StatusVerbal.Penalty) {
        store.dispatch('setShowModalFormDl', true)
      } else {
        apiUseCase.put(`${endpoint}/ChangeStatus/${itemChoice.Id}`, {
          MsStatusId: itemStatus.value
        })
          .then(({ error, message }) => {
            if (!error) {
              $toast.add({
                severity: 'success',
                detail: 'Berhasil merubah status',
                group: 'bc',
                closable: false,
                life: 3000
              })
              refHjpDataList.value.getData()
            } else {
              $toast.add({
                severity: 'error',
                detail: message,
                group: 'bc',
                closable: false,
                life: 3000
              })
            }
          })
      }
    }
    watch(searchValue, (newValue: string) => {
      params.searchValue = newValue
      setTimeout(() => {
        store.dispatch('setReloadDataList')
      }, 100)
    })
    return {
      params,
      mapResponse,
      processChangeStatus,
      refHjpDataList,
      itemSelected
    }
  }
})
