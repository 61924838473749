import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListVerbalReport = _resolveComponent("ListVerbalReport")!
  const _component_FineForm = _resolveComponent("FineForm")!
  const _component_HjpDataList = _resolveComponent("HjpDataList")!

  return (_openBlock(), _createBlock(_component_HjpDataList, _mergeProps(_ctx.params, { ref: "refHjpDataList" }), {
    content: _withCtx(({ dataItems }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mapResponse(dataItems), (data, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "mb-10",
          key: idx
        }, [
          _createVNode(_component_ListVerbalReport, {
            data: data,
            onOnChangeStatus: _ctx.processChangeStatus
          }, null, 8, ["data", "onOnChangeStatus"])
        ]))
      }), 128))
    ]),
    form: _withCtx(() => [
      _createVNode(_component_FineForm, { dataDetail: _ctx.itemSelected }, null, 8, ["dataDetail"])
    ]),
    _: 1
  }, 16))
}