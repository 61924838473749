
import { PropType, computed, defineComponent, reactive, ref, getCurrentInstance } from 'vue'
import { IVerbal, IVerbalItem, IItemStatus } from '@/utils/interfaces/iVerbal'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import StatusVerbalChip from '@/views/components/chip/StatusVerbal.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import moment from 'moment'
import InlineSvg from 'vue-inline-svg'
import OverlayPanel from 'primevue/overlaypanel'
import { StatusVerbal, StatusVerbalCode } from '@/utils/enum/StatusVerbal'
import ItemsStatus from '../data/listStatus'

export default defineComponent({
  name: 'ListVerbalReport',
  props: {
    data: {
      type: Object as PropType<IVerbalItem | null>,
      default: null
    }
  },
  components: {
    // Button,
    HjpCofirmModal,
    StatusVerbalChip,
    // InlineSvg,
    OverlayPanel
  },
  emits: ['onChangeStatus'],
  setup(props, { emit }) {
    const endpoint = 'transaction/v1/Verbal'
    const store = useStore()
    const router = useRouter()
    const dataItem = computed<IVerbalItem | null>(() => props.data)
    const app = getCurrentInstance()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties
    const selectedItem = ref<IVerbal>()
    const opStatus = ref()
    const listStatus = ref<IItemStatus[]>(ItemsStatus)

    const attrModalConfirmation = reactive({
      show: false,
      title: 'Approve Verpal?',
      subTitle: 'Apakah anda yakin ingin melakukan Approve Verpal?',
      btnSubmitTitle: 'Approve',
      imgContent: null
    })

    const onDetail = (verbal: IVerbal) => {
      router.push({
        name: 'verbal-report-detail',
        params: {
          id: verbal.Id
        }
      })
    }

    const hideDialogConfirmation = () => {
      attrModalConfirmation.show = false
    }

    const showDropDown = (evt: Event, item: IVerbal) => {
      opStatus.value.toggle(evt)
      selectedItem.value = item
    }

    const onChangeStatus = (item: IItemStatus) => {
      emit('onChangeStatus', item, selectedItem.value)
      opStatus.value.hide()
    }

    return {
      dataItem,
      moment,
      onDetail,
      attrModalConfirmation,
      hideDialogConfirmation,
      showDropDown,
      opStatus,
      listStatus,
      onChangeStatus,
      selectedItem,
      StatusVerbal,
      StatusVerbalCode
    }
  }
})
